import Api from './api';
import loadScript from './load-script';
import GlobalInstance from '../global-instance';

const getScenarioScript = async ({url, integrity}) => {
  await loadScript(url, integrity);
  const script = GlobalInstance[url];
  delete GlobalInstance[url];
  return script;
};

const createApi = (apiConfig, programConfig, scenarioConfig) => {
    const instance = new Api(apiConfig, programConfig, scenarioConfig);

    const wrapEvent = (event) =>
        ({
            on: (fn) => event.on(fn),
            off: (fn) => event.off(fn)
        });


    const api = {
        invite: (name) => { instance.setInvite(name); return api; },
        container: (name) => { instance.setContainer(name); return api; },
        survey: (surveyId) => { instance.setSurvey(surveyId); return api; },
        data: (data) => { instance.setSurveyData(data); return api; },
        show: () => { instance.show(); return api; },
        loadCountersAsync: async () => { await instance.loadCountersAsync(); return api; },
        scenarioCounters: (surveyId) => instance.getScenarioCounters(surveyId),

        events: {
            showInvite: wrapEvent(instance.events.showInvite),
            acceptInvite: wrapEvent(instance.events.acceptInvite),
            declineInvite: wrapEvent(instance.events.declineInvite),
            closeInvite: wrapEvent(instance.events.closeInvite),

            showContainer: wrapEvent(instance.events.showContainer),
            completeSurvey: wrapEvent(instance.events.completeSurvey),
            closeContainer: wrapEvent(instance.events.closeContainer),
        },
    };

    return api;
};

const run = async (programConfig, scenarioConfig) => {
    const apiFn = (apiConfig = {}) => createApi(apiConfig, programConfig, scenarioConfig);
    const script = await getScenarioScript(scenarioConfig);
    script(apiFn);
};

export default run;