import loadResource from './load-resource';
import runScenario from './run-scenario';
import logError from './log-error';

class ProgramConfig {
    constructor(json) {
        const {programKey, countersUrl, eventsUrl, scenarios, invites, overlays: containers, surveys} = json;

        this._programKey = programKey;
        this._countersUrl = countersUrl;
        this._eventsUrl = eventsUrl;
        this._scenarios = scenarios.map(x => new ScenarioConfig(x));
        this._invites = invites.map(x => new InviteConfig(x));
        this._containers = containers.map(x => new ContainerConfig(x));
        this._surveys = surveys.map(x => new SurveyConfig(x));
    }

    get programKey () { return this._programKey; }
    get countersUrl () { return this._countersUrl; }
    get eventsUrl () { return this._eventsUrl; }
    get scenarios() { return this._scenarios; }
    get invites() { return this._invites; }
    get containers() { return this._containers; }
    get surveys() { return this._surveys; }
}

class ScenarioConfig {
    constructor({id, link: {url, integrity}}) {
        this._id = id;
        this._url = url;
        this._integrity = integrity;
    }

    get id() { return this._id; }
    get url() { return this._url; }
    get integrity() { return this._integrity; }
}

class InviteConfig {
    constructor({id, name, url}) {
        this._id = id;
        this._name = name;
        this._url = url;
    }

    get id() { return this._id; }
    get name() { return this._name; }
    get url() { return this._url; }
}

class ContainerConfig {
    constructor({id, name, url}) {
        this._id = id;
        this._name = name;
        this._url = url;
    }

    get id() { return this._id; }
    get name() { return this._name; }
    get url() { return this._url; }
}

class SurveyConfig {
    constructor({id, url}) {
        this._id = id;
        this._url = url;
    }

    get id() { return this._id; }
    get url() { return this._url; }
}

const loadConfig = async (url) => {
    const {data} = await loadResource(url);
    return new ProgramConfig(data);
};

const run = async (url) => {
    const programConfig = await loadConfig(url);

    for (const scenarioConfig of programConfig.scenarios) {
        try {
            await runScenario(programConfig, scenarioConfig);
        }
        catch (e) {
            logError(e);
        }
    }
};

export default run;

