import axios from 'axios';
import Event from './event';
import loadResource from './load-resource';
import getSurveyUrlWithData from './survey-url-with-data';
import InviteView from './invite-view';
import ContainerView from './container-view';
import * as inviteEvents from './invite-events';
import * as containerEvents from './container-events';
import logError from './log-error';

const findByKey = (items, keyFn, key) => {
  if (typeof key !== 'string')
      return;

  return items.find(x => keyFn(x).toLowerCase() === key.toLowerCase());
};

export default class Api {
    constructor(apiConfig, programConfig, scenarioConfig){
        this._apiConfig = apiConfig;
        this._programConfig = programConfig;
        this._scenarioConfig = scenarioConfig;
        this._programCounters = [];

        this._state = {
            invite: undefined,
            container: undefined,
            survey: undefined,
            data: undefined,
        };

        this._events = {
            showInvite: new Event('api:show-invite'),
            acceptInvite: new Event('api:accept-invite'),
            declineInvite: new Event('api:decline-invite'),
            closeInvite: new Event('api:close-invite'),

            showContainer: new Event('api:show-container'),
            completeSurvey: new Event('api:complete-survey'),
            closeContainer: new Event('api:close-container'),
        };
    }

    get events() {
        return this._events;
    }

    setInvite (inviteName) {
        const {invites} = this._programConfig;
        const invite = findByKey(invites, x => x.name, inviteName);

        if (invite === undefined)
            throw new Error(`Cannot find invite '${inviteName}'`);

        this._setState({invite});
        loadResource(invite.url);
    }

    setContainer (containerName) {
        const {containers} = this._programConfig;
        const container = findByKey(containers, x => x.name, containerName);

        if (container === undefined)
            throw new Error(`Cannot find container '${containerName}'`);

        this._setState({container});
        loadResource(container.url);
    }

    setSurvey (surveyId) {
        const {surveys} = this._programConfig;
        const survey = findByKey(surveys, x => x.id, surveyId);

        if (survey === undefined)
            throw new Error(`Cannot find survey '${surveyId}'`);

        this._setState({survey})
    }

    setSurveyData (data) {
        this._setState({data});
    }

    show () {
        this._showInvite() || this._showContainer();
    }

    async loadCountersAsync () {
        const {countersUrl} = this._programConfig;
        const {data} = await loadResource(countersUrl);
        this._programCounters = data;
    }

    getScenarioCounters(surveyId) {
        const {id: scenarioId} = this._scenarioConfig;
        const defaultCounters = {
            completesCurrentHour: 0,
            completesCurrentDay: 0,
            completesCurrentWeek: 0,
            completesTotal: 0
        };
        const counters = this._programCounters.find(c => c.scenarioId === scenarioId && c.surveyId === surveyId);
        if (counters === undefined)
            return defaultCounters;

        return {...defaultCounters, ...counters};
    }

    _setState(obj = {}) {
        this._state = {...this._state, ...obj};
    }

    _showInvite () {
        const {invite} = this._state;
        if (invite === undefined)
            return;

        const view = new InviteView(invite.url);

        const inviteEventArgs = { model: invite, view };

        view.showInviteEvent.on(() => this._onInviteEvent(inviteEvents.InvitePresented, inviteEventArgs));
        view.acceptInviteEvent.on(() => this._onInviteEvent(inviteEvents.InviteAccepted, inviteEventArgs));
        view.declineInviteEvent.on(() => this._onInviteEvent(inviteEvents.InviteDeclined, inviteEventArgs));
        view.closeInviteEvent.on(() => this._onInviteEvent(inviteEvents.InviteClosed, inviteEventArgs));
        view.render().catch(logError);

        return view;
    }

    _onInviteEvent(eventType, inviteEventArgs) {
        this._triggerPublicInviteEvent(eventType, inviteEventArgs);
        this._triggerApiInviteEvent(eventType, inviteEventArgs);

        if (eventType === inviteEvents.InviteAccepted)
            this._showContainer();
    }

    _shouldTriggerApiInviteEvent(eventType) {
        const configKey = `count${eventType}`;
        const {[configKey]: configValue} = this._apiConfig;
        return !!configValue;
    }

    _triggerApiInviteEvent(eventType, { model }) {
        if (!this._shouldTriggerApiInviteEvent(eventType))
            return;

        const {eventsUrl, programKey} = this._programConfig;
        const {id: scenarioId} = this._scenarioConfig;

        const url = `${eventsUrl}&eventType=${eventType}&scenarioId=${scenarioId}&inviteId=${model.id}`;
        axios.get(url, {
            headers: {
                'X-ClientId': programKey
            }
        }).catch(logError);
    }

    _triggerPublicInviteEvent(eventType, { model, view }) {
        const publicEvents = {
            [inviteEvents.InvitePresented]: this._events.showInvite,
            [inviteEvents.InviteAccepted]: this._events.acceptInvite,
            [inviteEvents.InviteDeclined]: this._events.declineInvite,
            [inviteEvents.InviteClosed]: this._events.closeInvite,
        };

        const closeInvite = (fireEvent) => {
            view.close(fireEvent);
        };

        const event = publicEvents[eventType];
        event.trigger({ inviteName: model.name, closeInvite });
    }

    _showContainer () {
        const { container, survey, data } = this._state;
        if (container === undefined)
            return;

        const {programKey} = this._programConfig;
        const {id: scenarioId} = this._scenarioConfig;

        let surveyUrl;
        if (survey !== undefined) {
            surveyUrl = getSurveyUrlWithData(programKey, scenarioId, survey.url, data);
        }

        const view = new ContainerView(container.url, surveyUrl);

        const containerEventArgs = { model: container, view, survey, surveyUrl };

        view.showContainerEvent.on(() => this._onContainerEvent(containerEvents.ContainerPresented, containerEventArgs));
        view.closeContainerEvent.on(() => this._onContainerEvent(containerEvents.ContainerClosed, containerEventArgs));
        view.completeSurveyEvent.on(() => this._onContainerEvent(containerEvents.SurveyCompleted, containerEventArgs));
        view.render().catch(logError);

        return view;
    }

    _onContainerEvent(eventType, containerEventArgs) {
        this._triggerPublicContainerEvent(eventType, containerEventArgs);
    }

    _triggerPublicContainerEvent(eventType, { model, view, survey, surveyUrl }) {
        const publicEvents = {
            [containerEvents.ContainerPresented]: this._events.showContainer,
            [containerEvents.ContainerClosed]: this._events.closeContainer,
            [containerEvents.SurveyCompleted]: this._events.completeSurvey,
        };

        const closeContainer = (fireEvent) => {
            view.close(fireEvent);
        };

        const event = publicEvents[eventType];
        event.trigger({ containerName: model.name, surveyId: survey.id, surveyUrl, closeContainer });
    }
}