export default class Utils {
    static hide(node) {
        if(node.style.display !== 'none')
            node.style.display = 'none';
    }

    static show(node) {
        if(node.style.display === 'none')
            node.style.display = '';
    }

    static appendNode(html, target = document.body, hide = true){
        const dummy = document.createElement('div');
        dummy.innerHTML = html.trim();

        const node = dummy.firstChild;
        if (hide){
            this.hide(node);
        }

        return target.appendChild(node);
    }
}