import Event from './event';
import Utils from './utils';
import loadResource from './load-resource';

export default class ContainerView {
    constructor(containerUrl, surveyUrl){
        this._containerUrl = containerUrl;
        this._surveyUrl = surveyUrl;

        this._node = null;
        this._frame = null;
        this._autoHeight = false;

        this._showContainerEvent = new Event('container:show');
        this._completeSurveyEvent = new Event('survey:complete');
        this._closeContainerEvent = new Event('container:close');
    }

    get showContainerEvent(){
        return this._showContainerEvent;
    }

    get completeSurveyEvent (){
        return this._completeSurveyEvent;
    }

    get closeContainerEvent() {
        return this._closeContainerEvent;
    }

    async render() {
        this.close();

        const {data: {html, css}} = await loadResource(this._containerUrl);
        this._renderContents(html, css);
        this._attachEvents();
        this._setSurveyUrl();
        this._show();
    }

    close(fireEvent = false) {
        if (this._node) {
            this._node.parentNode.removeChild(this._node);
            this._node = null;

            if (fireEvent)
                this._closeContainerEvent.trigger();
        }
    }

    _renderContents(html, css) {
        this._node = Utils.appendNode(html);
        const styles = `<style>${css}</style>`;
        Utils.appendNode(styles, this._node);
        this._frame = this._node.querySelector('[data-container-frame]');
        this._adjustForAutoHeight();
    }

    _adjustForAutoHeight() {
        const isAutoHeight = this._node.hasAttribute('data-auto-height');
        const isMobile = this._isMobile();

        if (isAutoHeight && !isMobile) {
            this._autoHeight = true;
            this._frame.setAttribute('scrolling', 'no');
        }
        else {
            this._autoHeight = false;
            this._node.removeAttribute('data-auto-height');
        }
    }

    _isMobile(){
        const mobileThreshold = +this._node.getAttribute('data-mobile-threshold') || 640;
        const screenWidth = window.innerWidth || document.documentElement.clientWidth;
        const screenHeight = window.innerHeight || document.documentElement.clientHeight;
        return Math.min(screenWidth, screenHeight) <= mobileThreshold;
    }

    _attachEvents(){
        if (this._frame) {
            this._frame.addEventListener('load', () => this._surveyLoaded());
            window.addEventListener('message', event => this._receiveMessage(event))
        }

        const btnClose = this._node.querySelectorAll('[data-container-close]');
        btnClose.forEach(el => el.addEventListener('click', () => this.close(true)));
    }

    _show() {
        Utils.show(this._node);
        this._showContainerEvent.trigger();

        if (this._frame) {
            this._frame.focus();
        }
    }

    _surveyLoaded(){
        if (this._node) {
            const loader = this._node.querySelectorAll('[data-container-loader]');
            loader.forEach(el => Utils.hide(el));
        }
    }

    _receiveMessage(event) {
        if (event.source === this._frame.contentWindow
            && typeof event.data === 'object')
        {
            switch(event.data.type) {
                case 'cf-survey-end':
                    this._onSurveyEnd();
                    break;
                case 'cf-height-change':
                    this._onHeightChange(event.data.value);
                    break;
            }
        }
    }

    _onSurveyEnd() {
        this._completeSurveyEvent.trigger();
        this.close();
    }

    _onHeightChange(newHeight){
        if (!this._autoHeight || !this._frame) {
            return;
        }

        this._frame.style.height = newHeight + 'px';
    }

    _setSurveyUrl(){
        if (this._frame && this._surveyUrl)
            this._frame.src = this._surveyUrl;
    }
}
