import Event from './event';
import Utils from './utils';
import loadResource from './load-resource';

export default class InviteView {
    constructor(inviteUrl) {
        this._inviteUrl = inviteUrl;

        this._container = null;
        this._node = null;

        this._showInviteEvent = new Event('invite:show');
        this._acceptInviteEvent = new Event('invite:accept');
        this._declineInviteEvent = new Event('invite:decline');
        this._closeInviteEvent = new Event('invite:close');
    }

    get showInviteEvent () {
        return this._showInviteEvent;
    }

    get acceptInviteEvent(){
        return this._acceptInviteEvent;
    }

    get declineInviteEvent() {
        return this._declineInviteEvent;
    }

    get closeInviteEvent() {
        return this._closeInviteEvent;
    }

    async render() {
        this.close();

        this._renderContainer();

        const {data: {html, css}} = await loadResource(this._inviteUrl);
        this._renderContents(html, css);
        this._attachEvents();
        this._show();
    }

    close (fireEvent = false) {
        if (this._node) {
            this._node.parentNode.removeChild(this._node);
            this._node = null;

            if (fireEvent)
                this._closeInviteEvent.trigger();
        }
    }

    _renderContainer() {
        const containerHTML = '<div aria-live="polite"></div>';
        this._container = Utils.appendNode(containerHTML, document.body, false);
    }

    _renderContents(html, css){
        this._node = Utils.appendNode(html, this._container);

        const styles = `<style>${css}</style>`;
        Utils.appendNode(styles, this._node);
    }

    _attachEvents(){
        const acceptBtn = this._node.querySelectorAll('[data-invite-accept]');
        acceptBtn.forEach(btn => btn.addEventListener('click', () => this._accept()));

        const declineBtn = this._node.querySelectorAll('[data-invite-decline]');
        declineBtn.forEach(btn => btn.addEventListener('click', () => this._decline()));

        const closeBtn = this._node.querySelectorAll('[data-invite-close]');
        closeBtn.forEach(btn => btn.addEventListener('click', () => this.close(true)));
    }

    _show() {
        Utils.show(this._node);
        this._showInviteEvent.trigger();
    }

    _accept () {
        if (!this._node.hasAttribute('data-invite-persistent')) {
            this.close();
        }
        this._acceptInviteEvent.trigger();
    }

    _decline () {
        this.close();
        this._declineInviteEvent.trigger();
    }
}
