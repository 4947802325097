const loadScript = (url, integrity) => {
    const promise = new Promise((resolve, reject) => {
        try {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = url;
            script.async = true;
            if (integrity) {
                script.crossOrigin = 'anonymous';
                script.integrity = integrity;
            }
            script.addEventListener('load', () => resolve(), false);

            const firstScriptOnPage = document.getElementsByTagName('script')[0];
            firstScriptOnPage.parentNode.insertBefore(script, firstScriptOnPage);
        }
        catch(e) {
            reject(e);
        }
    });

    return promise;
};

export default loadScript;